import { InferGetStaticPropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { registerComponents } from '@/builder';
import Loading from '@/components/loading';
import PageModel from '@/models/PageModel';
import Page from '@/modules/pages/page';
import { HOME_PAGE_NAME } from '@/shared/constant';

registerComponents();

export async function getStaticProps() {
  const page = await PageModel.findPage({ params: { url: HOME_PAGE_NAME } });

  return {
    props: { page },
    revalidate: 5,
  };
}

const HoldingPage: FC<InferGetStaticPropsType<typeof getStaticProps>> = (props) => {
  const { page } = props;
  const router = useRouter();

  if (router.isFallback) return <Loading />;

  if (!page) return null;

  return (
    <>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify(
            {
              '@context': 'http://schema.org',
              '@type': 'Organization',
              url: 'http://www.dijitally.com/',
              contactPoint: [
                {
                  '@type': 'ContactPoint',
                  email: 'global@dijitally.com',
                  contactType: 'Customer Service',
                },
              ],
              address: [
                {
                  '@type': 'PostalAddress',
                  streetAddress: '701/88 Foveaux St',
                  addressLocality: 'Surry Hills, Sydney',
                  addressRegion: 'NSW',
                  postalCode: '2010',
                  addressCountry: 'Australia',
                },
              ],
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                  opens: '08:30',
                  closes: '17:30',
                },
              ],
            },
            null,
            2
          )}
        </script>
      </Head>
      <Page page={page} />
    </>
  );
};

export default HoldingPage;
