import BaseModel, { IFind } from './BaseModel';

class PageModel extends BaseModel {
  constructor() {
    super({ modelName: 'page' });
  }

  convertBuilderURL2Slug = (url: string) => {
    if (!url) return null;

    return url.replace(/^(\/page)/, '') ?? '';
  };

  convertSlug2BuilderURL = (slug: string) => {
    if (!slug) return null;

    return `/page/${slug}`;
  };

  getByUrl = (url: string | undefined) => {
    return this.builder
      .get(this.modelName, {
        userAttributes: {
          urlPath: url,
        },
      })
      .toPromise();
  };

  getAllPage = async (includeUnpublished = false, includeDraft = false) => {
    const draft = includeDraft ?? 'draft';
    const pages = await this.builder.getAll(this.modelName, {
      options: { noTargeting: true, includeUnpublished },
      query: {
        published: {
          $ni: ['archived', draft],
        },
      },
    });
    return pages;
  };

  findPage = async (filter: IFind) => {
    if (filter.params?.url) {
      filter.params.url = this.convertSlug2BuilderURL(filter.params.url) || undefined;
    }

    return this.find(filter);
  };
}

export default new PageModel();
