import { BuilderContent } from '@builder.io/sdk';
import { getAsyncProps } from '@builder.io/utils';
import { GetStaticPropsContext } from 'next';

import {
  BuilderProps as DijiBlogAllArticlesBuilderProps,
  DEFAULT_OFFSET,
  ROWS,
  SSProps as DijiBlogAllArticlesSSProps,
  TEMPLATE,
} from '@/components/diji-blog-all-articles/diji-blog-all-articles';
import { DijiBlogAllArticlesConfig } from '@/components/diji-blog-all-articles/diji-blog-all-articles.builder-component';
import { SSProps as HomeHeroSSProps } from '@/components/diji-blog-blog-home-hero/diji-blog-blog-hero';
import { BlogHomeHeroBuilderConfig } from '@/components/diji-blog-blog-home-hero/diji-blog-blog-home-hero.builder-component';
import {
  BuilderProps as DijiBlogFeaturedArticlesBuilderProps,
  SSProps as DijiBlogFeaturedArticlesSSProps,
} from '@/components/diji-blog-featured-articles/diji-blog-featured-articles';
import { DijiBlogFeaturedArticlesConfig } from '@/components/diji-blog-featured-articles/diji-blog-featured-articles.builder-component';
import {
  BuilderProps as HighlightArticleBuilderProps,
  SSProps as HighlightArticleSSProps,
} from '@/components/diji-blog-highlight-article/diji-blog-highlight-article';
import { DijiBlogHighlightArticleConfig } from '@/components/diji-blog-highlight-article/diji-blog-highlight-article.builder-component';
import ArticleModel from '@/models/ArticleModel';
import PageModel from '@/models/PageModel';
import { HOME_PAGE_NAME } from '@/shared/constant';
import {
  getFeaturedArticles,
  getHeroArticles,
  getHighlightArticle,
  getItemsPerPage,
  getMoreArticles,
  getNextArticle,
  getRelatedArticle,
} from '@/utils/Article';

export const getAsyncServerSideProps = (page: BuilderContent): ReturnType<typeof getAsyncProps> =>
  getAsyncProps(page, {
    async [BlogHomeHeroBuilderConfig.name](): Promise<HomeHeroSSProps> {
      const [leadArticle, ...articles] = await getHeroArticles();
      return {
        leadArticle,
        articles,
      };
    },
    async [DijiBlogHighlightArticleConfig.name](
      props: HighlightArticleBuilderProps
    ): Promise<HighlightArticleSSProps> {
      const fixedArticle = await getHighlightArticle(props.article);
      return {
        fixedArticle,
      };
    },

    async [DijiBlogFeaturedArticlesConfig.name](
      props: DijiBlogFeaturedArticlesBuilderProps
    ): Promise<DijiBlogFeaturedArticlesSSProps> {
      const fixedArticles = await getFeaturedArticles(props.articles);
      return {
        fixedArticles,
      };
    },
    async [DijiBlogAllArticlesConfig.name](
      props: DijiBlogAllArticlesBuilderProps
    ): Promise<DijiBlogAllArticlesSSProps> {
      const itemsPerPage = getItemsPerPage(ROWS, 0, TEMPLATE);
      const fixedArticles = await getMoreArticles(
        props.offset === undefined ? DEFAULT_OFFSET : props.offset,
        itemsPerPage
      );
      return {
        fixedArticles,
        currentRow: ROWS,
        defaultOffset: itemsPerPage,
        isLoading: false,
      };
    },
  });

export const getBlockDataMap = (content: BuilderContent) => {
  return (
    content.data?.blocks?.reduce((rs: { [key: string]: any }, block: any) => {
      if (!block?.component?.name) return rs;
      return {
        ...rs,
        [block.component.name]: block.component.options,
      };
    }, {}) || {}
  );
};

async function getPageStaticPaths(includeUnpublished?: boolean) {
  const pages = await PageModel.getAllPage(includeUnpublished);
  const paths = pages
    .filter((page: any) => page.data.url && page.data.url !== `/page/${HOME_PAGE_NAME}`)
    .map((page: any) => PageModel.convertBuilderURL2Slug(page.data.url));
  return {
    paths,
    fallback: true,
  };
}

async function getArticleStaticPaths(includeUnpublished?: boolean) {
  const pages = await ArticleModel.getAllArticles(includeUnpublished);
  const paths = pages
    .filter((page: any) => page.data.url)
    .map((page: any) => ArticleModel.convertBuilderURL2Slug(page.data.url));
  return {
    paths,
    fallback: true,
  };
}

export async function createStaticPaths(prefix: string, includeUnpublished?: boolean) {
  const pagesPage = (await getPageStaticPaths(includeUnpublished)).paths;
  const pageArticle = (await getArticleStaticPaths(includeUnpublished)).paths;
  const paths = [
    ...pagesPage.map((slug) => `${prefix}${slug}`),
    ...pageArticle.map((slug) => `${prefix}${slug}`),
  ];
  return {
    paths,
    fallback: true,
  };
}

export async function createStaticProps({
  params,
  includeUnpublished,
}: GetStaticPropsContext<any> & {
  includeUnpublished?: boolean;
}) {
  const slug = params?.slug;
  const pageSlug = Array.isArray(slug) ? slug.join('/') : slug;

  const page = await PageModel.findPage({ params: { url: pageSlug }, includeUnpublished });
  if (page) {
    await getAsyncServerSideProps(page);
    if (!includeUnpublished && pageSlug === HOME_PAGE_NAME) {
      return { notFound: true };
    }
    return {
      props: { page, model: 'page' },
      revalidate: 5,
    };
  }

  const article = await ArticleModel.findArticle({ params: { url: pageSlug }, includeUnpublished });
  if (article) {
    const [upNextArticle, relatedArticles] = await Promise.all([
      getNextArticle(article),
      getRelatedArticle(ArticleModel.fromBuilderContent(article)),
    ]);
    return {
      props: { page: article, model: 'article', upNextArticle, relatedArticles },
      revalidate: 5,
    };
  }

  return { notFound: true };
}
